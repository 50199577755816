<template>
  <div>
    <Header
        v-model="headerLinks" v-bind:headerLinks="headerLinks"
    ></Header>

    <div class="container mt-2">

<!--      <button class="btn btn-secondary"><i class="fa fa-plus"></i> Добавить</button>-->
      <button type="button" class="btn btn-warning" style="border-radius: 12px;" data-bs-toggle="modal" data-bs-target="#modalUsers" @click="modalNew">
        <i class="fa fa-plus"></i> Добавить
      </button>
      <div class="row">
<!--        <DatatableUserManagementBO :datatableId="'dt2'" :tableData="appData" :columnsData="columnsData2"/>-->
        <DatatableUserManagementBO v-if="loaded" :datatableId="'dt2'" :tableData="appData" :columnsData="columnsData2" @modalOpen="modalOpen" @deleteUser="deleteUser"/>
        <p v-else>
          Данные для отображения не найдены
        </p>
      </div>

    </div>

    <div class="modal fade" id="modalUsers" aria-labelledby="modalUsers" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" role="dialog">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Подсказка</h5>
            <button type="button" class="btn close" data-bs-dismiss="modal">
              <!--                <span>×</span>-->
              <i class="fa fa-times"></i>
            </button>
          </div>
          <div class="modal-body">
            <!--            <p>Статистика по загруженным пользователем данным</p>-->
            <div class="row">
              <div class="mb-3">
                <label for="fi1" class="form-label">Имя пользователя</label>
                <input type="text" class="form-control" style="border-radius: 12px;" id="fi1" v-model="modalData.username" placeholder="Имя пользователя">
              </div>
            </div>
            <div class="row" v-if="modalAdd">
              <div class="mb-3">
                <label for="fi4" class="form-label">Пароль</label>
                <input type="text" class="form-control" style="border-radius: 12px;" id="fi4" v-model="modalData.password" placeholder="Пароль">
              </div>
            </div>
            <div class="row">
              <div class="mb-3">
                <label for="fi2" class="form-label">Email</label>
                <input type="email" class="form-control" style="border-radius: 12px;" id="fi2" v-model="modalData.email" placeholder="Email">
              </div>
            </div>
            <div class="row">
              <div class="mb-3">
                <label for="fi3" class="form-label">Роль пользователя</label>
                <!--                <input type="text" class="form-control" id="fi3" v-model="modalData.role" placeholder="Роль пользователя">-->
                <select v-model="modalData.role" class="form-select" style="border-radius: 12px;" aria-label=".form-select-sm example" id="fi3">
<!--                  <option selected>Роль</option>-->
                  <option value="admin">Администратор</option>
                  <option value="user">Пользователь</option>
                </select>
              </div>
            </div>
            <div class="row" v-if="!modalAdd">
              <div class="mb-3">
                <label for="fi6" class="form-label">Сгенерировать пароль</label>
                <div class="input-group mb-3">
                  <button class="btn btn-secondary" @click="generatePass"><i class="fa fa-sm fa-key"></i></button>
                  <input type="text" class="form-control" style="border-radius: 12px;" id="fi6" v-model="modalData.password" placeholder="Пароль">
                </div>
              </div>
            </div>

<!--            <div class="row">-->
<!--              <div class="mb-3">-->
<!--                <label for="fi4" class="form-label">Компания</label>-->
<!--                <VueMultiselect-->
<!--                    v-model="modalData.company_id"-->
<!--                    :options="companies"-->
<!--                    :multiple="false"-->
<!--                    :close-on-select="true"-->
<!--                    placeholder="Компания"-->
<!--                    label="name"-->
<!--                    track-by="id"-->
<!--                />-->

<!--              </div>-->
<!--            </div>-->

          </div>
          <div class="modal-footer">
            <button v-if="modalAdd" type="button" class="btn btn-warning" style="border-radius: 12px;" data-bs-dismiss="modal" @click="addNewUser">Добавить</button>
            <button v-else type="button" class="btn btn-warning" style="border-radius: 12px;" data-bs-dismiss="modal" @click="editUser">Сохранить</button>
            <button type="button" class="btn btn-warning" style="border-radius: 12px;" data-bs-dismiss="modal" @click="cancelUserData">Отмена</button>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Header from "@/components/Header";
import DatatableUserManagementBO from "@/components/Datatables/DatatableUserManagementBO";
import Config from "@/config";
import {TCompanies, Users} from "@/utils/coreApi";
import generatePassword from "@/utils/passUtils";
// import VueMultiselect from 'vue-multiselect'

export default {
  name: "UserManagementBO",
  components: {
    Header,
    DatatableUserManagementBO,
    // VueMultiselect
  },
  data () {
    return {
      headerLinks: [
        {
          name: "Управление пользователями BO",
          url: "/user_management_bo"
        },
      ],
      groups2: [
        {
          id: 1,
          name: "Пользователь 1",
          login: "user1",
          email: "test1@beeline.ru",
          description: "Описание 1",
        },
        {
          id: 2,
          name: "Пользователь 2",
          login: "user2",
          email: "test2@beeline.ru",
          description: "Описание 2",
        },
        {
          id: 3,
          name: "Пользователь 3",
          login: "user3",
          email: "test3@beeline.ru",
          description: "Описание 3",
        },
      ],
      columnsData2: [
        // { title: "Наименование", visible: true },
        { title: "Имя", visible: true, key: 'username', sort: true, search: true },
        { title: "Email", visible: true, key: 'email', sort: true, search: true },
        { title: "Описание", visible: true, key: 'description', sort: true, search: true },
        { title: "Роль", visible: true, key: 'role', sort: true, search: true },
        { title: "Редактировать", visible: true },
        { title: "Удалить", visible: true },
      ],
      appData: [],
      appLoadData: false,
      modalAdd: false,
      modalData: {},
      loaded: false,
      loadedC: false,
      companies: [],
      companiesData: [],
    }
  },
  async mounted() {
    await this.getData();
    await this.getCompanyData();
  },
  methods: {
    generatePass() {
      this.modalData.password = generatePassword()
      console.log("this.modalData.password", this.modalData.password)
    },
    async getData() {
      this.appData = []
      this.appLoadData = false;
      this.loaded = false;
      try {
        // console.log("getToken", this.$store.getters['users/getToken'])

        let token = this.$store.getters['users/getToken']
        // let token = "848ef78eff7e433f92320a3f9595d237"
        const config = {
          headers: { Authorization: `Bearer ${token}` }
        };

        let  url = Config.apiUrl + '/users/';
        // let url = 'http://localhost:8000'
        const respState = await this.$http.get(url, config).catch(error => {
          console.log(error)
          if (error.response.status === 401) {
            this.$store.dispatch('users/CancelAuthorize');
          }
          return {}
        })
        // const respState = await this.$http.get(url + '/utils/state/', config)
        // const respState = await this.$http.get('https://demo2.aispeech.online/utils/state/')
        if (respState.status === 200) {

          // let tempAppData = JSON.parse(JSON.stringify(respState.data))
          let tempAppData = respState.data

          tempAppData.forEach(
              // element => console.log(element)
              element => {
                console.log("element", element)
                let t = element
                this.appData.push({
                  "id": t.id,
                  "username": t.username,
                  "company_id": t.company_id,
                  "role": t.role,
                  "email": t.email,
                  "is_active": t.is_active
                })
              }
          );

          // this.appData = JSON.parse(JSON.stringify(respState.data))
          // this.appData = respState.data
          this.loaded = true
          console.log("this.appData ", this.appData )
          this.appLoadData = true;
        }
      } catch (error) {
        console.log(error)
      }
    },
    async getCompanyData () {
      this.loadedC = false
      this.companies = []
      let companiesObj = new TCompanies()
      let companies =  await companiesObj.getAll()
      console.log("companies", companies)
      if (companies && companies.length > 0) {
        this.companies = companies
        // scenariosData.forEach(value => {
        //   this.scenarioOptions.push({"name": value.name, "id": value.id})
        // })
        this.loadedC = true
      }
      // console.log("this.scenarioData", this.scenarioData)
    },
    modalOpen(modelData) {
      this.modalData = modelData
      this.modalAdd = false
      console.log("modalData", this.modalData)
    },
    modalNew() {
      this.modalData = {}
      this.modalAdd = true
    },
    async addNewUser() {
      let userObj = new Users()
      let newUserData = {
        "username": this.modalData.username,
        "password": this.modalData.password,
        "role": this.modalData.role,
        "email": this.modalData.email,
        "is_active": true,
        "auth_token": "token123",
        "company_id": 1,
        "group_id": 1,
        "report_list": "1;2;3;4;5;6;7;8",
        "main_reports_list": "1;2;3;4;5;6;7;8",
        "filter_main_reports_list": ""
      }
      let resp = await userObj.add(newUserData)
      console.log("resp add", resp)
    },
    async editUser() {
      let userObj = new Users()
      let currentUser = await userObj.get(this.modalData.id)
      if (currentUser) {
        let newUserData = currentUser
        newUserData.username = this.modalData.username
        newUserData.role = this.modalData.role
        newUserData.email = this.modalData.email
        if (this.modalData.password !== '')
        {
          newUserData.password = this.modalData.password
        }
        let resp = await userObj.update(this.modalData.id, newUserData)
        console.log("resp add", resp)
      }
    },
    async deleteUser(user) {
      let userObj = new Users()
      let resp = await userObj.delete(user.id)
      console.log("resp delete", resp)
      if (resp.status === 'ok') {
        await this.getData();
      }
    },
    async cancelUserData() {
      await this.getData();
    }
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>

<!--<style scoped>-->

<!--</style>-->
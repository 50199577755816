<template>
  <div>
    <div class="table_margin mb-2 mt-3">
      <div class="row">
        <div class="col-md-10"></div>
        <!-- <div class="col-md-2"> -->
          <!-- <input type="text" class="form-control" id="pfi1" v-model="search" placeholder="Поиск" @input="searchData"/> -->
        <!-- </div> -->
      </div>
      <table class="table table-hover">
        <thead>
        <tr>
          <th v-for="(column, index) in columns" :key="index">
            <a v-if="column.visible" @click="sortColumn(column, index)">{{ column.title }}
              <template v-if="column.sort">
                <i v-if="column.sortAsc" class="fa fa-sm fa-arrow-down"></i>
                <i v-else class="fa fa-sm fa-arrow-up"></i>
              </template>
            </a>
            <template v-if="column.search">
              <input type="text" class="form-control" style="border-radius: 12px;" v-model="column.searchValue" placeholder="Поиск" @input="searchColumnData(column, columns )"/>
            </template>
          </th>
        </tr>
        </thead>
      <tbody>
      <tr v-for="user in users" :key="user.id">
<!--        <td>{{user.name}}</td>-->
<!--        <td>{{user.login}}</td>-->
<!--        <td>{{user.email}}</td>-->
<!--        <td>{{user.description}}</td>-->

        <td>{{user.username}}</td>
        <td>{{user.email}}</td>
<!--        <td>{{user.number}}</td>-->
<!--        <td>{{user.group}}</td>-->

        <td>{{user.description}}</td>
        <td>{{user.role}}</td>

        <td>
          <button class="btn btn-lg btn-outline-$gray300" style="border-radius: 12px; color: #000000;" data-bs-toggle="modal" data-bs-target="#modalUsers" @click="editUser(user)">
            <i class="fa fa-pen"></i>
          </button>
        </td>
        <td>
          <button class="btn btn-lg btn-outline-$gray300" style="border-radius: 12px; color: #000000;"><i class="fa fa-times" @click="deleteUser(user)"></i></button>
        </td>
      </tr>
      </tbody>
    </table>
    </div>

    <div v-if="modalUsers" class="modal">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Подсказка</h5>
              <button type="button" class="close" data-dismiss="modal">
                <span>×</span>
              </button>
            </div>
            <div class="modal-body">
              <p>Статистика по загруженным пользователем данным</p>
            </div>
          </div>
        </div>

    </div>

  </div>
</template>

<script>

import searchTable from "@/utils/dataUtils";

export default {
  name: "DatatableUserManagementBO",
  props: {
    datatableId: String,
    tableData: [],
    columnsData: []
  },
  data() {
    return {
      search: '',
      origData: [],
      users: [],
      columns: [],
      demoData: [['0','1','2','3','4','5','6','7','8','9','10','11','12']],
      modalUsers: false,
      modalGroups: false
    }
  },
  mounted(){
    this.users = this.tableData
    this.origData = this.tableData
    this.demoData = []
    this.columnsData.forEach(value => {
      const newValue = value
      newValue.sortAsc = true
      newValue.searchValue = ''
      this.columns.push(newValue)
    })
  },
  methods: {
    searchData() {
      if (this.search) {
        this.users = searchTable(this.users, this.search, '');
      } else {
        this.users = this.origData
      }
    },
    searchColumnData(column, columns) {
      if (column.searchValue) {
        this.users = searchTable(this.users, column.searchValue, column.key);
      } else {
        let m = false
        columns.forEach(value => {
          if (value.searchValue) {
            m = true
          }
        })
        if (!m) {
          this.users = this.origData
        }
      }
    },
    sortColumn(key, index) {
      if (key.sort) {
        if (this.columns[index].sortAsc) {
          this.sortAscending(key.key)
        } else {
          this.sortDescending(key.key)
        }
        const sortDirection = this.columns[index].sortAsc
        this.columns.forEach(value => {
          value.sortAsc = true
        })
        this.columns[index].sortAsc = !sortDirection
      }
    },
    sortAscending(key) {
      this.users.sort((a,b)=> (a[key] > b[key] ? 1 : -1))
    },
    sortDescending(key) {
      this.users.sort((a,b)=> (a[key] < b[key] ? 1 : -1))
    },

    editUser(reportData) {
      this.$emit('modalOpen', reportData)
    },
    deleteUser(reportData) {
      this.$emit('deleteUser', reportData)
    }
  }
}
</script>

<style scoped>

.table_margin {
  margin-left: 15px;
  margin-right: 15px;
}

</style>